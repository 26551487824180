import { ErrorBoundary, StandaloneConfiguration } from '@amzn/sitc-frontend/components';
import { backlotLightTheme } from '@amzn/sitc-frontend-theme';
import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import { MICRO_APP_NAME } from './utils/constants';

const ScriptSightsApp = React.lazy(() => import('./ScriptSightsApp'));

const configuration = { appId: 'studios-portal-app' };
const rootComponent = () => (
  <StandaloneConfiguration
    RootComponent={ScriptSightsApp}
    configuration={configuration}
    microAppName={MICRO_APP_NAME}
    standaloneModeFeatures={{ microAppUserPool: true }}
  />
);

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent,
  errorBoundary: (error: Error, info: React.ErrorInfo) => (
    <ThemeProvider theme={backlotLightTheme}>
      <CssBaseline />
      <ErrorBoundary error={error} info={info} />
    </ThemeProvider>
  ),
});

export const { bootstrap, mount, unmount } = lifecycles;
